<template>
    <div>
        <div
            @click="toggleFiltro"
            @keypress.enter="toggleFiltro"
            role="button"
            class="d-flex justify-content-between p-2 bg-color rounded align-items-center font-weight-bold"
        >
            Filtra le prenotazioni
            <div class="p-2 ">
                <span v-if="!filtroOpen"><i class="fas fa-plus"></i></span>
                <span v-else><i class="fas fa-minus"></i></span>
            </div>
        </div>
        <div class="container px-4 py-3 shadow" v-if="filtroOpen">
            <div class="row">
                <div class="col-6 my-2">
                    <label>Dalla data</label>
                    <div class="input-group ">
                        <div
                            class="input-group-prepend"
                            :style="
                                filters.start_time ? 'width: 95%' : 'width:100%'
                            "
                        >
                            <input type="date" v-model="filters.start_time" ref="start_time" />
                        </div>
                        <div class="input-group-append">
                            <span
                                @click="filters.start_time = null"
                                @keypress.enter="filters.start_time = null"
                                v-if="filters.start_time"
                                role="button"
                                class="reset-date"
                                tabindex="0"
                            >
                                <span class="sr-only">resetta</span>
                                <i class="fas fa-times"></i>
                            </span>
                        </div>
                    </div>
                </div>
                <div class="col-6 my-2">
                    <label>Alla data</label>
                    <div class="input-group ">
                        <div
                            class="input-group-prepend"
                            :style="
                                filters.end_time ? 'width: 95%' : 'width:100%'
                            "
                        >
                            <input type="date" v-model="end_time" ref="end_time" />
                        </div>
                        <div class="input-group-append">
                            <span
                                @click="filters.end_time = null"
                                @keypress.enter="filters.end_time = null"
                                v-if="filters.end_time"
                                role="button"
                                class="reset-date"
                                tabindex="0"
                            >
                                <span class="sr-only">resetta</span>
                                <i class="fas fa-times"></i>
                            </span>
                        </div>
                    </div>
                </div>
                <div class="col-6 my-2">
                    <label>Servizio</label>
                    <Select2
                        v-if="data.servizi"
                        v-model="filters.servizio"
                        allowClear
                        :options="data.servizi"
                        :placeholder="'seleziona il servizio'"
                    />
                </div>
                <div class="col-6 my-2">
                    <label>Area</label>
                    <Select2
                        v-if="data.aree"
                        v-model="filters.area"
                        allowClear
                        :options="data.aree"
                        :placeholder="'seleziona l\'area'"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            filters: {
                start_time: null,
                end_time: null,
                servizio: null,
                area: null
            },
            data: {
                servizi: null,
                aree: null
            },
            filtroOpen: false
        };
    },
    computed : {
        end_time : {
            set : function (val) {
                this.filters.end_time = val;
                if(this.filters.start_time && new Date(val) < new Date(this.filters.start_time)){
                    this.filters.end_time = this.filters.start_time;
                }
            },
            get: function () {return this.filters.end_time}
        }
    },
    watch: {
        filters: {
            handler() {
                this.$emit("updated", this.filters);
            },
            deep: true
        }
    },
    mounted() {
        this.getFiltersData();
    },
    methods: {
        getFiltersData() {
            return new Promise((resolve, reject) => {
                let url = "/api/reservations/filter";
                this.$http
                    .get(url)
                    .then(res => {
                        this.data.servizi = res.data.servizi;
                        this.data.aree = Array.from(res.data.aree);
                        resolve();
                    })
                    .catch(err => {
                        reject(err);
                    });
            });
        },
        toggleFiltro() {
            this.filtroOpen = !this.filtroOpen;
        }
    }
};
</script>
<style>
.bg-color {
    background-color: var(--custom-color-0);

    color: var(--custom-color-text-0);
}
.reset-date {
    border-bottom: 1px solid black;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>
