<template>
    <div class="mt-5">
        <error-banner v-if="errors" close />

        <filtro @updated="updateFilters" />
        <div
            class="table-responsive mt-5"
            v-if="bookings && bookings.length > 0"
        >
            <div class="table-container position-relative">
                <div :class="isLoading && 'loading-mask'">
                    <loading-spinner v-if="isLoading"></loading-spinner>
                </div>
                <div class="d-flex justify-content-end mb-3">
                    <button
                        class="btn btn-primary"
                        @click="toggleRepetitionPopUp"
                        @keypress.enter="toggleRepetitionPopUp"
                    >
                        {{ $t("buttons.ripeti_prenotazioni") }}
                    </button>
                </div>

                <table class="table" aria-live="polite">
                    <caption class="sr-only">
                        {{
                            $t("my_bookings.tabella_prenotazioni")
                        }}
                    </caption>
                    <thead
                        :style="{
                            backgroundColor: this.$store.state.Ente.colors
                                ? this.$store.state.Ente.colors.sfondo[0]
                                : '',
                            color: 'white'
                        }"
                    >
                        <tr class="cursor-pointer">
                            <th scope="col" class="d-md-table-cell">
                                <input
                                    type="checkbox"
                                    v-model="selectedAll"
                                    aria-label="Seleziona tutto"
                                />
                            </th>
                            <table-header
                                v-for="header in headers"
                                :key="header.key"
                                :order-key="header.key"
                                :order="sortBy.field === header.key ? sortBy.order : 'asc'"
                                :sortable="header.sortable"
                                :class="header.class"
                                :label="header.label"
                                @sortBy="setTableOrder"
                            />
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            :class="repeat.new ? notify : ''"
                            :aria-description="
                                repeat.new ? 'Prenotazione appena inserita' : ''
                            "
                            v-for="repeat in bookings"
                            :key="repeat.codice"
                        >
                            <td
                                class="d-md-table-cell"
                                style="text-align:center;"
                            >
                                <input
                                    type="checkbox"
                                    v-model="repeat.selected"
                                    :aria-label="`Seleziona ${repeat.codice}`"
                                />
                            </td>
                            <td
                                @click="show(repeat)"
                                @keyup.enter="show(repeat)"
                                tabindex="0"
                                role="button"
                                :aria-label="
                                    `Mostra dettagli per ${repeat.codice}`
                                "
                            >
                                {{ repeat.codice }}
                            </td>

                            <td
                                @click="show(repeat)"
                                @keyup.enter="show(repeat)"
                                tabindex="0"
                                role="button"
                                :aria-label="
                                    `Stato: ${$t(
                                        'stati_prenotazione.' + repeat.stato
                                    )}`
                                "
                            >
                                {{ $t("stati_prenotazione." + repeat.stato) }}
                            </td>
                            <td
                                @click="show(repeat)"
                                @keyup.enter="show(repeat)"
                                tabindex="0"
                                role="button"
                                :aria-label="
                                    `Servizio: ${repeat.entry.servizio}`
                                "
                            >
                                {{ repeat.entry.servizio }}
                            </td>
                            <td
                                @click="show(repeat)"
                                @keyup.enter="show(repeat)"
                                tabindex="0"
                                role="button"
                                :aria-label="
                                    `Data: ${new Date(
                                        repeat.entry.day_time
                                    ).toLocaleDateString(lang, {
                                        weekday: 'long',
                                        year: 'numeric',
                                        month: 'long',
                                        day: 'numeric'
                                    })}`
                                "
                            >
                                {{
                                    new Date(
                                        repeat.entry.day_time
                                    ).toLocaleDateString(lang, {
                                        weekday: "long",
                                        year: "numeric",
                                        month: "long",
                                        day: "numeric"
                                    })
                                }}
                                <span class="d-md-none d-inline">{{
                                    repeat.entry.from_time
                                        .split(":")
                                        .slice(0, -1)
                                        .join(":")
                                }}</span>
                            </td>
                            <td
                                class="d-none d-md-table-cell"
                                @click="show(repeat)"
                                @keyup.enter="show(repeat)"
                                tabindex="0"
                                role="button"
                                :aria-label="
                                    `Ora: ${repeat.entry.from_time
                                        .split(':')
                                        .slice(0, -1)
                                        .join(':')}`
                                "
                            >
                                {{
                                    repeat.entry.from_time
                                        .split(":")
                                        .slice(0, -1)
                                        .join(":")
                                }}
                            </td>
                            <td
                                class="d-none d-md-table-cell"
                                @click="show(repeat)"
                                @keyup.enter="show(repeat)"
                                tabindex="0"
                                role="button"
                                :aria-label="
                                    `Sede: ${repeat.entry.risorsa.area.area_name}`
                                "
                            >
                                {{ repeat.entry.risorsa.area.area_name }}
                            </td>
                            <td
                                class="d-none d-md-table-cell"
                                @click="show(repeat)"
                                @keyup.enter="show(repeat)"
                                tabindex="0"
                                role="button"
                                :aria-label="
                                    `Indirizzo: ${repeat.entry.risorsa.area.address}`
                                "
                                v-html="repeat.entry.risorsa.area.address"
                            ></td>
                            <td
                                class="d-none d-md-table-cell"
                                @click="show(repeat)"
                                @keyup.enter="show(repeat)"
                                tabindex="0"
                                role="button"
                                style="text-align:center;"
                                :aria-label="`Visualizza ${repeat.codice}`"
                            >
                                <router-link
                                    :to="{
                                        name: 'Show',
                                        params: {
                                            ente: $route.params.ente,
                                            codice: repeat.codice
                                        },
                                        query: {
                                            primaria: repeat.public_primary
                                        }
                                    }"
                                    ><i
                                        class="fas fa-external-link-alt mx-auto"
                                    ></i
                                ></router-link>
                            </td>
                            <td
                                class="d-md-table-cell"
                                style="text-align:center;"
                            >
                                <router-link
                                    :to="{
                                        name: $routes.PRENOTA.CALENDARIO,
                                        params: {
                                            servizio: repeat.entry.servizio_id,
                                            area: repeat.entry.risorsa.area.id
                                        }
                                    }"
                                    ><i class="fas fa-redo"></i
                                ></router-link>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <nav
                    :aria-label="$t('my_bookings.pagina_prenotazioni')"
                    class="d-flex flex-row-reverse"
                >
                    <ul class="pagination">
                        <li
                            class="page-item"
                            :class="page == 1 ? 'disabled' : ''"
                        >
                            <a
                                class="page-link"
                                :aria-label="$t('pagination.previous')"
                                @click="getBookings(page - 1)"
                                @keyup.enter="getBookings(page - 1)"
                                role="button"
                                tabindex="0"
                            >
                                <span aria-hidden="true">&laquo;</span>
                                <span class="sr-only">{{
                                    $t("pagination.previous")
                                }}</span>
                            </a>
                        </li>
                        <li
                            v-for="(p, i) in pages"
                            :key="i"
                            :class="p == page ? 'active' : ''"
                        >
                            <a
                                class="page-link"
                                @click="getBookings(p)"
                                @keyup.enter="getBookings(p)"
                                role="button"
                                tabindex="0"
                                >{{ p }}</a
                            >
                        </li>

                        <li
                            class="page-item"
                            :class="pages == page ? 'disabled' : ''"
                        >
                            <a
                                class="page-link"
                                :aria-label="$t('pagination.next')"
                                @click="getBookings(page + 1)"
                                @keyup.enter="getBookings(page + 1)"
                                role="button"
                                tabindex="0"
                            >
                                <span aria-hidden="true">&raquo;</span>
                                <span class="sr-only">{{
                                    $t("pagination.next")
                                }}</span>
                            </a>
                        </li>
                    </ul>
                </nav>

                <PopUpRipetizione
                    ref="popup"
                    :selectedReservations="selezionate"
                    v-if="repetitionPopUp"
                    @closed="toggleRepetitionPopUp"
                    @updated="updatePage"
                />
            </div>

            <div
                :class="isLoading && 'loading-mask'"
                v-if="isLoading && !bookings"
            >
                <loading-spinner></loading-spinner>
            </div>
        </div>
        <div
            class="d-flex flex-column mt-5 justify-content-center align-items-center"
            v-else
        >
            <div>
                <img
                    src="@/assets/images/search-no-result.jpg"
                    class="my-3 p-2"
                    style="width: 300px"
                    alt="icona"
                />
            </div>

            <div class="font-weight-bold">
                {{ $t("my_bookings.nessuna_prenotazione") }}
            </div>
        </div>
    </div>
</template>

<script>
import Cookies from "js-cookie";
import LoadingSpinner from "../components/layout/LoadingSpinner.vue";
import Filtro from "../components/MyBookings/Filtro.vue";
import PopUpRipetizione from "../components/MyBookings/PopUpRipetizione.vue";
//@vuese
// @group Views
//Pagina che mostra prenotazioni dell'utente o permette la ricerca con chiave primaria-codice
export default {
    components: { LoadingSpinner, Filtro, PopUpRipetizione },
    name: "MyBookings",
    data() {
        return {
            bookings: null,
            page: 1,
            pages: null,
            isLoading: true,
            repetitionPopUp: false,
            dataSelectedAll: false,
            filters: {},
            notify: "",
            sortBy: { field: "data", order: "asc" }
        };
    },
    computed: {
        headers() {
            return [
                {
                    label: this.$t("codice"),
                },
                {
                    label: this.$t("stato"),
                },
                {
                    label: this.$t("servizio"),
                },
                {
                    label: this.$t("data"),
                    key : "data",
                    sortable: true
                },
                {
                    label: this.$t("ora"),
                    class: "d-none d-md-table-cell"
                },
                {
                    label: this.$t("sede"),
                    class: "d-none d-md-table-cell"
                },
                {
                    label: this.$t("indirizzo"),
                    class: "d-none d-md-table-cell"
                },
                {
                    label: this.$t("visualizza"),
                    class: "d-none d-md-table-cell"
                },
                {
                    label: this.$t("my_booking.riprenota"),
                }
            ];
        },
        url() {
            return process.env.VUE_APP_CLIENT_URL;
        },
        allSelected() {
            return this.selezionate.length == this.bookings.length;
        },
        selezionate() {
            return this.bookings.filter(e => e.selected);
        },
        selectedAll: {
            set: function(value) {
                if (value) {
                    this.bookings = this.bookings.map(booking => ({
                        ...booking,
                        selected: true
                    }));
                } else {
                    this.bookings = this.bookings.map(booking => ({
                        ...booking,
                        selected: false
                    }));
                }

                this.dataSelectedAll = value;
            },
            get: function() {
                return this.dataSelectedAll;
            }
        }
    },
    mounted() {
        this.getBookings();
    },
    watch: {
        filters: {
            handler: function() {
                this.getBookings();
            },
            deep: true
        },
        sortBy: {
            handler: function() {
                this.getBookings();
            },
            deep: true
        }
    },
    methods: {
        updateFilters(newFilters) {
            this.filters = newFilters;
        },
        toggleRepetitionPopUp() {
            this.repetitionPopUp = !this.repetitionPopUp;
            if (this.repetitionPupUp) this.$refs.popup.focus();
        },
        async updatePage() {
            const old_bookings = new Set(this.bookings.map(e => e.codice));
            await this.getBookings();
            this.bookings.forEach((element, key) => {
                this.$set(
                    this.bookings[key],
                    "new",
                    !old_bookings.has(element.codice)
                );
            });
            this.notify = "green-background";
            setTimeout(() => {
                this.notify = "white-background";
            }, 1000);
        },
        /**
         * @vuese
         * funzione asincrona ottiene prenotazioni per l'utente loggato
         */
        async getBookings(page = 1) {
            this.isLoading = true;
            this.page = page;
            let url = `api/reservations/${this.cliente.id}?page=${page}`;
            if (this.sortBy.field)
                url +=
                    "&sortBy=" +
                    this.sortBy.field +
                    "&order=" +
                    this.sortBy.order;

            const res = await this.$http.post(url, {
                user: Cookies.get("user"),
                filters: this.filters
            });
            this.pages = res.data.last_page;
            this.bookings = res.data.data.map(booking => ({
                ...booking,
                selected: false
            }));
            this.isLoading = false;
        },
        setTableOrder(field) {
            if (this.sortBy.field === field) {
                if (this.sortBy.order === "asc") this.sortBy.order = "desc";
                else this.sortBy.order = "asc";
            } else this.sortBy.order = "asc";

            this.sortBy.field == "field";
        },
        /**
         * @vuese
         * Naviga nella views Show
         */
        show(repeat) {
            this.$router.push({
                name: "Show",
                params: {
                    ente: this.$route.params.ente,
                    codice: repeat.codice
                },
                query: {
                    primaria: repeat.public_primary
                }
            });
        }
    }
};
</script>

<style>
td {
    text-align: center;
    vertical-align: middle;
}

.cuesor-pointer {
    cursor: pointer;
}

.active {
    color: blue;
}

.loading-mask {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #fbfbfb9d;
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;
}

.green-background {
    background-color: rgba(40, 167, 69, 0.3);
}

/* Definisce la classe per lo sfondo bianco */
.white-background {
    background-color: white;
    transition: background-color 1s ease-in-out;
}
</style>
