<template>
    <modal @close="$emit('closed')" aria-live="assertive" close-btn tabindex="0">
        <template v-if="selectedReservations.length > 0">
            <template slot="body" class="p-5">
                <div class="row">
                    <div class="col-12">
                        <label for="data_ripetizione">{{$t('my_bookings.data_input')}}</label>
                        <div class="input-group mb-3 w-100">
                            <div
                                class="input-group-prepend"
                                :style="
                                    start_time ? 'width: 95%' : 'width:100%'
                                "
                            >
                                <input
                                    type="text"
                                    ref="input"
                                    v-model="start_time"
                                    id="data_ripetizione"
                                />
                            </div>
                            <div class="input-group-append">
                                <span
                                    @click="resetDatePicker"
                                    @keypress.enter="resetDatePicker"
                                    v-if="start_time"
                                    role="button"
                                    class="reset-date"
                                    tabindex="0"
                                >
                                    <span class="sr-only">resetta</span>
                                    <i class="fas fa-times"></i>
                                </span>
                            </div>
                        </div>
                    </div>

                    <e-button @click="confirm" @keypress.enter="confirm"
                        >Conferma</e-button
                    >
                </div>
            </template>
        </template>
        <template v-else>
            <template slot="body">
                Seleziona almeno una prenotazione da ripetere
            </template>
        </template>
    </modal>
</template>

<script>
import "bootstrap-datepicker/dist/js/bootstrap-datepicker.min.js";
import "bootstrap-datepicker/dist/css/bootstrap-datepicker3.min.css";
import "bootstrap-datepicker/dist/locales/bootstrap-datepicker.it.min.js";
import $ from "jquery";

export default {
    props: {
        selectedReservations: {
            type: Array,
            required: true
        }
    },
    mounted() {
        const vm = this;
        this.datePicker = $(this.$refs.input);

        this.datePicker
            .datepicker({
                multidate: true,
                startDate: "+1d",
                format: "dd/mm/yyyy",
                language: this.lang
            })
            .on("changeDate", function() {
                vm.updateStartTime(vm.datePicker.val());
            });
    },

    data() {
        return {
            savedReservations: [],
            start_time: "", // Inizialmente vuoto
            datePicker: null
        };
    },
    methods: {
        resetDatePicker(){
            this.start_time = '';
            this.datePicker.datepicker('update', '');
        },
        updateStartTime(val) {
            this.start_time = val;
        },
        async confirm() {
            try {
                const date = this.start_time.split(",");
                const promises = [];

                for (const reservation of this.selectedReservations) {
                    for (const datePart of date) {
                        const promise = this.$http
                            .post(
                                `/api/entry/ripeti/${reservation.repeat_id}`,
                                {
                                    start_time: datePart
                                }
                            )
                            .then(res => {
                                this.savedReservations.push({
                                    codice: res.data.codice_prenotazione,
                                    primaria: res.data.public_primary
                                });
                                return res;
                            });
                        promises.push(promise);
                    }
                }

                await Promise.all(promises);

                this.setMessage({
                    name: "effettuata",
                    message: "Prenotazioni effettuate con successo"
                });

                this.$emit("updated");
                this.$emit("closed");
            } catch (e) {
                console.error(e);
                this.savedReservations.forEach(entry => this.elimina(...entry));
                this.$emit("closed");
            }
        },

        elimina(codice, primaria) {
            this.$http
                .post(`api/entry/delete/${codice}?chiave=${primaria}`, {
                    force: true
                })
                .catch(e => {
                    this.setErrors(e);
                });
        }
    }
};
</script>
